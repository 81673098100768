import { dev } from '$app/environment';
import { PUBLIC_LOG_LEVEL_CLIENT, PUBLIC_LOG_SOURCES, PUBLIC_SENTRY_DSN } from '$env/static/public';
import { create_log, init_logging } from '$lib/shared/logging';
import * as Sentry from '@sentry/sveltekit';

init_logging(PUBLIC_LOG_SOURCES, PUBLIC_LOG_LEVEL_CLIENT);

Sentry.init({
	dsn: PUBLIC_SENTRY_DSN,
	environment: dev ? 'local' : 'production',

	// We recommend adjusting this value in production, or using tracesSampler for finer control
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,

	// Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
	// tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

	// Optional: Initialize Session Replay:
	integrations: [
		// Sentry.browserTracingIntegration(),
		Sentry.replayIntegration()
	],

	// Capture Replay for 10% of all sessions, plus 100% of sessions with an error
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});

const log = create_log('endpoint', { sentry: false });

export function handleError({ error, event, status, message }) {
	const { params, route, url } = event;

	log.error(message, { status, error, params, route, url });
	Sentry.captureException(error, { extra: { event, status }, tags: { source: log.source } });
}
