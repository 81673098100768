import * as Sentry from '@sentry/sveltekit';

export interface Log {
	source: string;
	debug(message: string, params?: Object): void;
	error(message: string, params?: Object): void;
	info(message: string, params?: Object): void;
	warning(message: string, params?: Object): void;
	enabled(): boolean;
}

export type LogLevel =
	| 'debug'
	| 'error'
	| 'info'
	| 'warning';

export function init_logging(sources: string, min_level: string) {
	for (const source of sources.split(',')) {
		_enabled_sources.add(source.trim());
	}

	const levels: LogLevel[] = ['error', 'warning', 'info', 'debug'];

	_allowed_log_levels = levels.slice(
		0,
		levels.indexOf(min_level as LogLevel) + 1
	);
}

export function create_log(
	source: string,
	options: {
		sentry?: boolean
	} = {}
): Log {
	const { sentry = true } = options;

	return {
		source,
		enabled() {
			return _enabled_sources.has(source);
		},
		debug(message, params) {
			write('debug', message, params);
		},
		error(message, params) {
			write('error', message, params);
		},
		info(message, params) {
			write('info', message, params);
		},
		warning(message, params) {
			write('warning', message, params);
		}
	};

	function write(level: LogLevel, message: string, params?: unknown) {
		if (sentry && level !== 'debug')
			Sentry.captureMessage(message, { level, tags: { source }, extra: { params } });

		if (!_enabled_sources.has(source))
			return;

		if (!_allowed_log_levels.includes(level))
			return;

		const timestamp = new Date().toLocaleTimeString('sv', { timeStyle: 'medium' });
		const log_message = `${timestamp} [${source}] ${message}`;
		const log_params = params ? [params] : [];

		switch (level) {
			case 'debug': console.debug(log_message, ...log_params); break;
			case 'error': console.error(log_message, ...log_params); break;
			case 'info': console.info(log_message, ...log_params); break;
			case 'warning': console.warn(log_message, ...log_params); break;
		}
	}
}

const _enabled_sources = new Set<string>();
let _allowed_log_levels: LogLevel[] = [];
